<template>
  <UiSearchSuggestions
    :mode="searchSuggestionsCurrentMode"
    :is-loading="loading"
    :search-phrase="searchPhrase"
    :labels="labels"
    :enable-search-phrase-suggestions="props.enableSearchPhraseSuggestions && selectedSearchBarOption.value !== SearchBarOptions.EAN"
    :enable-search-phrase-categories="props.enableSearchPhraseCategories"
    :search-phrase-suggestions="searchPhrasePopularTerms"
    :search-phrase-categories="searchPhraseCategories"
    :class="[{ '!py-12': !searchSuggestionsItems?.length && initialized }]"
    :products-container-class="[{ '!px-12': !searchSuggestionsItems?.length && initialized }]"
    data-test="app-search-suggestions"
  >
    <template #products>
      <template v-if="searchSuggestionsItems?.length">
        <p
          class="mb-2.5 border-y border-y-blue-450 py-3 text-primary lg:hidden"
        >
          {{ t('ecom_products_meeting_criteria') }}
        </p>
        <M2ProductSearchItem
          v-for="(product, index) in searchSuggestionsItems"
          :key="product?.uid"
          :index="index"
          :product="product"
          :disabled="props.disabled"
          :refetch-cart="props.refetchCart"
          :global-data-loading="loading"
          :individual-products-data-loading="individualProductsDataInternalPending"
          data-test="app-search-product-item"
        />
      </template>

      <div
        v-if="!searchSuggestionsItems?.length && initialized"
        data-test="app-search-suggestions-empty-msg"
      >
        <p class="mb-10 text-lg font-bold text-primary">
          {{ t('ecom_no_products_related_to_the_search_phrase') }}
        </p>

        <div>
          <p class="mb-6 text-primary">
            {{ `${t('try_again_one_more_time')}:` }}
          </p>
          <ul class="flex list-inside list-disc flex-col gap-6 pl-2.5 text-base text-primary">
            <template
              v-for="(point, index) in noSearchSuggestionsListPoints"
              :key="`point-${index}`"
            >
              <li v-if="point.visible">
                {{ point.label }}
              </li>
            </template>
          </ul>

          <ul class="mt-5 flex flex-wrap items-center gap-7 pl-8">
            <li
              v-for="(option) in searchBarOptionsList"
              :key="option.value"
              class="first:w-full first:lg:w-auto"
            >
              <UiButton
                variant="link"
                icon-right="arrow"
                :uppercase="false"
                :class="[{ '!underline underline-offset-2 opacity-90': selectedSearchBarOption.value === option.value }]"
                @click="selectedSearchBarOption = option"
              >
                {{ option.label }}
              </UiButton>
            </li>
          </ul>
        </div>
      </div>
      <template v-else-if="!searchSuggestionsItems?.length && !initialized">
        <UiLoadingLayer />
      </template>
    </template>

    <template
      v-if="props.enableShowMoreProducts && searchSuggestionsItems?.length"
      #moreProducts="{ label }"
    >
      <UiButton
        variant="link"
        icon-right="arrow"
        :icon-dimensions="{ width: 6, height: 10 }"
        class="mb-7 mt-4 pr-5 normal-case lg:mb-0"
        @click="handleSearch"
      >
        {{ label }}
      </UiButton>
    </template>
  </UiSearchSuggestions>
</template>

<script lang="ts" setup>
import { useAppNav } from '@base/components/AppNav/useAppNav'
import useIndividualProductData from '@ecom/composables/product/useIndividualProductData'
import { useSearchSuggestions } from '../../composables/useSearchSuggestions'
import { useSearch } from '../../composables/useSearch/useSearch'
import { SearchBarOptions } from '../../stores/search'
import { SearchCharactersLength } from '../../components/AppSearch/AppSearch.types'
import type { M2SearchSuggestionsProps } from './M2SearchSuggestions.types'

const props = withDefaults(defineProps<M2SearchSuggestionsProps>(), {
  suggestionsLimit: 6,
  enableShowMoreProducts: true,
  enableSearchPhraseSuggestions: true,
  enableSearchPhraseCategories: true,
  refetchCart: false,
  disabled: false,
})

const emit = defineEmits<{
  (e: 'exact-product', link: string | undefined): void
  (e: 'update:loading', value: boolean): void
  (e: 'search', value: string): void
  (e: 'close'): void
  (e: 'suggestions'): void
}>()

const LIMITS = {
  categories: 15,
  popularTerms: 8,
}
const { productLink } = useAppNav()
const { t } = useI18n()
const { isLoggedIn } = useCustomer()
const {
  loading,
  searchSuggestionsCurrentMode,
  labels,
  initSearchSuggestions,
  searchSuggestionsResults,
  searchSuggestionsItems,
  getSearchPhraseCategories,
  getSearchPopularTerms,
  findExactProductBySuggestions,
} = useSearchSuggestions()
const { selectedSearchBarOption, searchBarOptionsList } = useSearch()
const { getIndividualProductsData } = useIndividualProductData()

const initialized = ref(false)
const individualProductsDataInternalPending = ref(false)

const searchPhraseCategories = computed(() => {
  return getSearchPhraseCategories(LIMITS.categories)
})

const searchPhrasePopularTerms = computed(() => {
  return getSearchPopularTerms(LIMITS.popularTerms)
})

function handleSearch() {
  emit('search', props.searchPhrase)
  emit('close')
}

const noSearchSuggestionsListPoints = computed(() => ([
  {
    label: t('ecom_check_if_the_name_is_typed_correctly'),
    visible: true,
  },
  {
    label: t('ecom_try_using_other_more_general_words'),
    visible: true,
  },
  {
    label: `${t('ecom_search_by_another_criterion')}:`,
    visible: true,
  },
]))

watch(
  (): [string, SearchBarOptions] => [props.searchPhrase, selectedSearchBarOption.value.value],
  async ([newSearchPhrase, newSelectedSearchBarOption]) => {
    if ((newSearchPhrase as string).length >= SearchCharactersLength.MIN) {
      const params = { search: props.searchPhrase, pageSize: props.suggestionsLimit }
      await initSearchSuggestions(params, newSelectedSearchBarOption)
      if (isLoggedIn.value) {
        individualProductsDataInternalPending.value = true
        try {
          await getIndividualProductsData(searchSuggestionsItems.value)
        }
        finally {
          individualProductsDataInternalPending.value = false
        }
      }
      emit('suggestions')
      if (!initialized.value) {
        initialized.value = true
      }
    }
  },
  { immediate: true },
)

watch(loading, (newValue) => {
  emit('update:loading', newValue)
})

watch(searchSuggestionsItems, (newList) => {
  const { exactProduct, productStartsWithSku } = findExactProductBySuggestions(props.searchPhrase, newList)
  if (productStartsWithSku || !exactProduct) {
    emit('exact-product', undefined)
    return
  }
  emit('exact-product', productLink(exactProduct?.canonical_url ?? ''))
})

watch(loading, (newValue) => {
  emit('update:loading', newValue)
})

onUnmounted(() => {
  if (props.searchPhrase.length >= SearchCharactersLength.MIN) {
    return
  }

  searchSuggestionsResults.value = null
  initialized.value = false
})
</script>
